import React, { Component, Fragment } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import {
  FaBars,
  FaLayerGroup,
  FaGlobeAmericas,
  FaCode,
  FaGift,
  FaTimes,
  FaRocket,
  FaDatabase,
  FaBolt,
  FaEngineWarning,
} from 'react-icons/fa'
import { sampleArray } from '../kubesailHelpers.js'
import ContextSelector from '../util/ContextSelector'
// import { getBaseUnits } from '../util'

const PiBoxIcon = dynamic(() => import('../icons').then(i => i.PiBoxIcon))

const SHOW_CONTEXT_SELECTOR_AFTER_SCROLL = 100

// function getUsage(used /*: string */, limit /*: string */) {
//   return Math.min(1, Math.max(0, getBaseUnits(used) / getBaseUnits(limit || 100)))
// }

class Sidebar extends Component {
  sectionsNode = null

  state = {
    expanded: false,
    upgradeHidden: false,
    showContextSelector: false,
  }

  menuClick = () => {
    this.setState({ expanded: true })
    const handleClick = e => {
      if (
        e.target.classList.value.includes('menu') ||
        e.target.classList.value.includes('icon') ||
        (!e.target.classList.value.includes('label') &&
          this.sectionsNode &&
          this.sectionsNode.contains(e.target))
      ) {
        return
      }
      this.setState({ expanded: false })
      document.body.removeEventListener('click', handleClick)
    }
    document.body.addEventListener('click', handleClick)
  }

  scrollHandler = () => {
    this.setState({ showContextSelector: window.scrollY > SHOW_CONTEXT_SELECTOR_AFTER_SCROLL })
  }

  bottomMessages = [
    null,
    null,
    null,
    <>
      <p>Did you know?</p>
      <p>
        The <Link href="/repos">repo builder</Link> allows you to automatically build GitHub repos
        on-commit, on your own hardware - even at home!
      </p>
    </>,
    null,
    null,
    null,
    <>
      <p>Heads up!</p>
      <p>
        Our custom plug-n-play hardware is ready to order!{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://pibox.io/">
          Check out PiBox
        </a>
        : the self-hosting machine!
      </p>
    </>,
    null,
    null,
    null,
    this.props?.profile?.currentContext?.address ? (
      <>
        <p>Did you know?</p>
        <p>
          You can open a terminal on your nodes via the&nbsp;
          <Link href={`/clusters/${this.props.profile.currentContext.address}/nodes`}>
            KubeSail Cluster Nodes panel
          </Link>{' '}
          from anywhere!
        </p>
      </>
    ) : null,
    null,
    null,
    null,
    this.props?.profile?.currentContext?.address ? (
      <>
        <p>Did you know?</p>
        <p>
          You can build a business on KubeSail!&nbsp;
          <Link href={`/platforms`}>The Platform Portal</Link> lets you charge your own customers
          and launch apps when their payment goes through!
        </p>
      </>
    ) : null,
    null,
    null,
    null,
    this.props?.profile?.currentContext?.address ? (
      <>
        <p>Did you know?</p>
        <p>
          You can invite friends and co-workers to your clusters on the&nbsp;
          <Link href={`/clusters/${this.props.profile.currentContext.address}/users`}>
            KubeSail Cluster Users panel
          </Link>
        </p>
      </>
    ) : null,
    null,
    null,
    null,
    <>
      <p>Get unlimited builds, disk backups, and multiple users.</p>
      <p style={{ textAlign: 'right' }}>
        <Link href="/pricing">Upgrade to KubeSail Teams now!</Link>
      </p>
    </>,
    null,
    null,
    null,
    <>
      <p>Did you know?</p>
      <p>
        You can <strong>backup any PersistentVolumeClaim</strong> to the KubeSail Cloud by clicking
        the volume and clicking backup, even from home!
      </p>
    </>,
  ]

  componentDidMount = () => {
    document.addEventListener('scroll', this.scrollHandler)
    let messageIncr = 0
    try {
      messageIncr = parseInt(window.localStorage.getItem('sidebar-message-incr'), 10)
      if (messageIncr > this.bottomMessages.length - 1) messageIncr = 0
      window.localStorage.setItem('sidebar-message-incr', messageIncr + 1 || 1)
    } catch {
      messageIncr = sampleArray(this.bottomMessages, true)
    }
    this.setState({ bottomMessage: this.bottomMessages[messageIncr] })
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.scrollHandler)
  }

  renderSection = ({ icon, name, urlPath, as, className, title, children, suffix, spacer }) => {
    if (spacer) return <div key={name} className="spacer" />
    if (!as) urlPath = (this.props.pathPrefix || '') + urlPath
    const asParts = this.props.router.asPath.split('/')
    asParts.shift()

    const isSelected =
      (this.props.router.asPath === '/dashboard/all' && name === 'Resources') ||
      (this.props.router.asPath.startsWith('/template') && name === 'App Store') ||
      name.toLowerCase() === asParts[0] ||
      name.toLowerCase() === asParts[0] + 's' ||
      name.toLowerCase() + 's' === asParts[0] ||
      name.toLowerCase().replace(/ /g, '-') === asParts[2] ||
      (name === 'Apps' &&
        asParts[0] === 'dashboard' &&
        this.props.router.asPath !== '/dashboard/all') ||
      (this.props.selected || '').toLowerCase() === name.toLowerCase()

    return (
      <Fragment key={name}>
        <Link href={urlPath} as={as || urlPath} passHref>
          <li
            onClick={() => {
              typeof this.props.onChange === 'function' && this.props.onChange(name)
            }}
            className={`sidebar-item ${name.replace(' ').toLowerCase()} ${className || ''} ${
              isSelected ? 'selected' : ''
            }`}
            style={{ display: 'flex' }}
            title={title || ''}
          >
            <div className="icon">{icon}</div>
            <div className="label">{name}</div>
            {/* {suffix ? (
              <div style={{ marginLeft: '5px' }} className="suffix icon">
                {suffix}
              </div>
            ) : null} */}
          </li>
        </Link>
        {children ? <div className="children">{children.map(this.renderSection)}</div> : null}
      </Fragment>
    )
  }

  render() {
    const sidebarClassName = `Sidebar translate ${this.props.className || ''} ${
      this.state.expanded ? 'expanded' : ''
    } ${this.props.editor.showing ? 'editor-open' : 'editor-closed'} ${
      this.props.collapsable ? 'collapsable' : ''
    }`

    const defaultSections = [
      {
        urlPath: '/dashboard' || '',
        name: 'Apps',
        icon: <FaRocket />,
        title:
          'View applications installed in this namespace and on this cluster. Apps include Deployments and Stateful Sets',
      },

      {
        urlPath: `/templates`,
        urlPrefix: '/templates',
        name: 'App Store',
        icon: <FaGift />,
        title: 'View apps which are ready to install anywhere! Browse official or community apps.',
      },
      this.props?.profile?.features?.storage
        ? {
            urlPath: '/storage' || '',
            name: 'Storage',
            icon: <FaDatabase />,
            title: 'View and access your storage volumes',
          }
        : null,
      {
        spacer: true,
        name: 'spacer1',
      },
      {
        urlPath: '/domains',
        name: 'Domains',
        icon: <FaGlobeAmericas />,
        title: 'View and add domains which can be used with your Apps and Ingresses',
      },
      {
        urlPath: `/clusters`,
        urlPrefix: '/clusters',
        name: 'Clusters',
        icon: <PiBoxIcon />,
        suffix:
          this.props?.profile?.clusters &&
          this.props?.profile?.clusters.find(c => {
            return !c.verified && !c.isNew
          }) ? (
            <FaEngineWarning title="A new cluster is pending approval!" className="warn" />
          ) : undefined,
        title:
          'Attach clusters, invite other users to your clusters, ssh to Nodes, and install features!',
      },
      {
        urlPath: '/dashboard' || '',
        as: '/dashboard/all',
        name: 'Resources',
        icon: <FaLayerGroup />,
        title: 'View all Kubernetes resources in this namespace - useful for experienced users',
      },
      {
        spacer: true,
        name: 'spacer2',
      },
      {
        urlPath: '/repos',
        urlPrefix: '/repos',
        name: 'Repos',
        icon: <FaCode />,
        title:
          'View and attach GitHub Repositories - automatically build and deploy projects on your clusters!',
      },
      {
        urlPath: `/platforms`,
        name: 'Platform',
        icon: <FaBolt />,
        title:
          'KubeSail Platform allows you to build a business out of your Clusters and Templates - build a PaaS in minutes!',
      },
    ].filter(Boolean)

    const sections = this.props.sections || defaultSections

    return (
      <div className={sidebarClassName}>
        {this.props.hideMenu ? null : (
          <div className="menu" onClick={this.menuClick}>
            <div className="icon">
              <FaBars className="icon" />
            </div>
          </div>
        )}
        <div
          className={`sections ${this.state.expanded ? 'expanded' : ''}`}
          ref={node => (this.sectionsNode = node)}
        >
          {this.props.children}
          {sections.filter(s => !s.hidden).map(this.renderSection)}
        </div>
        {this.state.showContextSelector && this.props.allowContextSelector ? (
          <ContextSelector statusOnly={true} className="context-selector" />
        ) : null}
        {this.props.showUpgradePrompt && !this.state.upgradeHidden && this.state.bottomMessage ? (
          <div className="bottom-message">
            <div className="close" onClick={() => this.setState({ upgradeHidden: true })}>
              <FaTimes />
            </div>
            <div className="bubble">{this.state.bottomMessage}</div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(({ profile, docs, editor }) => {
  return { profile, docs, editor }
})(withRouter(Sidebar))
