// @flow

import React, { Component } from 'react'
import Link from 'next/link'
import { COMMIT_HASH } from '../config'
import cloudguySad from '../assets/cloudguy-sad-small.png'

// $FlowIssue
export class ErrorBoundary extends Component {
  state /*: any */ = { error: null }

  componentDidCatch(error /*: Error */, errorInfo /*: any */) {
    if (window.rollbar) {
      window.rollbar.error(error) // Send it to Rollbar!
    }
    console.error(error, errorInfo)
  }

  static getDerivedStateFromError(error /*: Error */) {
    return { error }
  }

  render() {
    if (typeof this.state.error === 'object' && this.state.error) {
      if (this.props.FallbackComponent) return this.props.FallbackComponent(this.state.error)
      return (
        <div className="ListItem error">
          <h2>{this.state.error.toString()}</h2>
          <pre className="error-stack">{this.state.error.stack}</pre>
        </div>
      )
    } else if (this.state.error) {
      throw new Error(this.state.error)
    }
    return this.props.children
  }
}

export const FallbackComponent = (kind = 'component' /*: string */, className /*: string */) => {
  return function FallbackComponentErrorHandler(error) {
    return (
      <div className={className || 'Page Error'}>
        <h2 style={{ marginBottom: '2rem' }}>KubeSail encountered an error!</h2>
        <img loading="lazy" src={cloudguySad.src} alt="Sorry, something went wrong..." />
        <div>
          There was an error rendering this {kind}. We received a report and will investigate ASAP!
        </div>
        {error ? (
          <div style={{ textAlign: 'center', margin: '2rem' }}>
            <div style={{ textAlign: 'center', margin: '1rem' }}>
              The error was: <code>{error.message}</code>
            </div>
            <pre style={{ whitespace: 'pre', margin: '0 auto', textAlign: 'left' }}>
              {(error.stack || '').replace('\\n', <br />)}
            </pre>
            <div style={{ textAlign: 'center', margin: '1rem' }}>Version: {COMMIT_HASH}</div>
          </div>
        ) : null}
        <div style={{ paddingBottom: '4rem' }}>
          <span>Please feel free to join us in our&nbsp;</span>
          <Link href="/support" as="/support" prefetch={false}>
            support chat
          </Link>
          <span>&nbsp;if we can do anything else to help while we fix this bug!</span>
        </div>
      </div>
    )
  }
}
